// APIにGETでアクセスする際のクエリストリングスをオブジェクトから生成する関数
// 引数：オブジェクト
// 返値：クエリストリングス
export function makeQueryStrings (params: object): string {
  let retQueryStrings = ''
  Object.keys(params).forEach(key => {
    if (retQueryStrings === '') {
      retQueryStrings = '?'
    } else {
      retQueryStrings += '&'
    }
    retQueryStrings += key + '=' + (params[key] as string)
  });
  return retQueryStrings
}
